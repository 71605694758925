/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { graphWrapper, person, blogPosting, 
  organization, imageObject } from "schema-comsat"
import { convertBreadcrumb } from "./schema-translate"

function SchemaBlogPosting({ breadcrumbs, datePublished, title, pathname }) {
  const { site, squarebox, ampLogo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            canonicalUrl
            author
          }
        }
        squarebox: imageSharp(
          original: { src: { regex: "/logo-squarebox/" } }
        ) {
          id
          original {
            src
            height
            width
          }
        }
        ampLogo: imageSharp(original: { src: { regex: "/logo-amp-logo/" } }) {
          id
          original {
            src
            height
            width
          }
        }
      }
    `
  )

  const rootUrl = site.siteMetadata.canonicalUrl

  const schema = graphWrapper([
    blogPosting({
      "id": rootUrl + pathname,
      author: person({name: site.siteMetadata.author, url: rootUrl}),
      image: [squarebox.original.src],
      datePublished,
      mainEntityOfPage: rootUrl + pathname,
      headline: title,
      publisher: organization({
        name: site.siteMetadata.title,
        url: rootUrl,
        logo: imageObject({
          height: ampLogo.original.height,
          width: ampLogo.original.width,
          url: ampLogo.original.src,
        }),
      }),
    }),
    convertBreadcrumb(rootUrl, breadcrumbs)
  ]);

  return (
    <Helmet>
      <meta property="article:published_time" content={datePublished} />
      <meta name="twitter:card" content="summary" />
      <meta property="og:type" content="article" />
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

SchemaBlogPosting.propTypes = {
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  datePublished: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.any,
}

export default SchemaBlogPosting
